import { render, staticRenderFns } from "./StorePackage.vue?vue&type=template&id=36e81d00&scoped=true"
import script from "./StorePackage.vue?vue&type=script&lang=ts"
export * from "./StorePackage.vue?vue&type=script&lang=ts"
import style0 from "./StorePackage.vue?vue&type=style&index=0&id=36e81d00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e81d00",
  null
  
)

export default component.exports