


































































import { Vue, Component, Prop } from "vue-property-decorator";
import IProductDto from "@/chipply/i-product-dto";
import IPackageDto from "@/chipply/i-package-dto";
import PackageDesignFlag from "@/components/PackageDesignFlag.vue";

@Component({
    components: { PackageDesignFlag },
})
export default class ProductImageSlider extends Vue {
    @Prop({ type: Object })
    public packageDto!: IPackageDto;
    public mouseDown = false;
    public imageListId = this.generateUniqueElementId();
    public sliderContainerId = this.generateUniqueElementId();
    public scrollByXRight = 220;
    public scrollByXLeft = -220;
    public startX = 0;
    public scrollLeftValue = 0;
    public shouldShowScrollButtons = false;

    public mounted() {
        window.addEventListener("resize", this.checkImageSliderContainerSize);
        this.checkImageSliderContainerSize();
    }
    public destroyed() {
        window.removeEventListener("resize", this.checkImageSliderContainerSize);
    }
    public checkImageSliderContainerSize() {
        setTimeout(() => {
            const container = document.getElementById(this.sliderContainerId);
            const containerDimensions = container!.getBoundingClientRect();
            const numOfProducts = this.packageDto.products.products.length;
            const imageContainerWidth = 174;
            const sliderContainerMaxWidth = 915;
            const combinedImageWidth = numOfProducts * imageContainerWidth;
            const hasMoreImagesThanContainerWidth = combinedImageWidth > sliderContainerMaxWidth;
            const containerWidthTooSmallToFitAllImages = containerDimensions.width < combinedImageWidth;
            this.shouldShowScrollButtons = hasMoreImagesThanContainerWidth || containerWidthTooSmallToFitAllImages;
        }, 5);
    }
    public generateUniqueElementId() {
        return `id_${Math.random().toString(16).slice(2)}`;
    }
    public getImageList = () => {
        return document.getElementById(this.imageListId);
    };

    public scrollImages = (scrollByX: number) => {
        const imageList = this.getImageList();
        imageList!.style.scrollBehavior = "smooth";
        imageList!.scrollBy(scrollByX, 0);
        imageList!.style.scrollBehavior = "auto";
    };

    public mouseUp = (e: any) => {
        const list = this.getImageList();
        this.mouseDown = false;
        list!.classList.remove("active");
        e.preventDefault();
    };

    public mouseLeave = () => {
        const list = this.getImageList();
        this.mouseDown = false;
        list!.classList.remove("active");
    };

    public mouseDownClicked = (e: any) => {
        const list = this.getImageList();
        e.preventDefault();
        this.mouseDown = true;
        list!.classList.add("active");
        this.startX = e.pageX - list!.offsetLeft;
        this.scrollLeftValue = list!.scrollLeft;
    };

    public mouseMove = (e: any) => {
        const list = this.getImageList();
        if (!this.mouseDown) return;
        e.preventDefault();
        const x = e.pageX - list!.offsetLeft;
        const walk = (x - this.startX) * 2;
        list!.scrollLeft = this.scrollLeftValue - walk;
    };

    private toolTipText(product: IProductDto) {
        let ele = document.createElement("div");
        if (product.shortDesc) {
            ele.innerHTML = product.shortDesc;
        }
        return ele.innerText;
    }

    private descText(product: IProductDto) {
        const ele = document.createElement("div");
        if (product.shortDesc) {
            ele.innerHTML = product.shortDesc;
            const target = ele.getElementsByTagName("span")[0];
            if (target) {
                target.style.fontSize = "14px";
            }
        }
        return ele.innerHTML;
    }
}
