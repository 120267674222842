var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.carousel)?_c('v-app',{staticStyle:{"width":"100%","height":"400px"}},[_c('v-carousel',{staticClass:"myCarousel",attrs:{"height":"400","hide-delimiter-background":"","show-arrows":_vm.value.product.designCount > 1},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"z-index":"10"},attrs:{"color":"#056DB9","icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"visibility":"visible"}},[_vm._v("$iconChevronLeft")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"z-index":"10"},attrs:{"color":"#056DB9","icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"visibility":"visible"}},[_vm._v("$iconChevronRight")])],1)]}}],null,false,1502799204)},[(_vm.value.product.designCount > 1)?_c('design-flag',{staticStyle:{"z-index":"2"},attrs:{"quantity":_vm.value.product.designCount}}):_vm._e(),_vm._l((_vm.images),function(img,imgIndex){return _c('v-carousel-item',{key:imgIndex},[_c('v-img',{style:({
                    backgroundColor: img.isArtwork && _vm.selectedHexValue ? _vm.selectedHexValue : 'rgb(230, 230, 230)',
                }),attrs:{"src":img.previewImageSrc}})],1)})],2)],1):(_vm.packageImage)?_c('div',{staticClass:"package-image-parent-div",attrs:{"id":"packageImage"}},[_c('div',{staticClass:"package-primary-image-container",staticStyle:{"width":"361px","height":"361px"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('img',{ref:"zoomImg",staticStyle:{"height":"100%","width":"100%"},style:({ cursor: _vm.packageImageZoomActive ? 'zoom-out' : 'zoom-in' }),attrs:{"src":_vm.value.selectedImage.previewImageSrc,"data-zoom":_vm.value.selectedImage.previewImageSrc},on:{"click":function($event){_vm.toggleOverlay((_vm.packageImageZoomActive = !_vm.packageImageZoomActive))}}}),_c('div',{ref:"zoomPane",style:({ display: _vm.showZoom }),attrs:{"id":"zoomPane"},on:{"click":function($event){_vm.toggleOverlay((_vm.packageImageZoomActive = !_vm.packageImageZoomActive))}}})])]),(_vm.images.length > 1)?_c('div',{staticStyle:{"display":"flex","padding-left":"2px","width":"100%","align-items":"flex-start","gap":"3%","z-index":"2"}},_vm._l((_vm.images),function(img,imgIndex){return _c('div',{key:imgIndex,staticClass:"package-additional-image-container",style:({ border: img.enabled ? '2px solid #1E1515' : '2px solid #909093' }),on:{"click":function($event){return _vm.selectImage(img)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"Product Image","src":img.imageSrc}})])}),0):_vm._e()]):_c('v-app',{staticStyle:{"width":"100%","height":"400px"},attrs:{"id":"ImageViewer"}},[(_vm.value.product.designCount > 1)?_c('design-flag',{staticStyle:{"z-index":"2"},attrs:{"quantity":_vm.value.product.designCount}}):_vm._e(),_c('div',{staticStyle:{"background":"white","border":"1px solid #e3e2e5","max-width":"679px"}},[_c('div',{ref:"zoomPane",staticStyle:{"position":"absolute","width":"700px","height":"400px","left":"calc(100%)","z-index":"2"},style:({
                display: _vm.showZoom,
                backgroundColor:
                    _vm.value.selectedImage.isArtwork && _vm.selectedHexValue ? _vm.selectedHexValue : 'rgb(230, 230, 230)',
            }),attrs:{"id":"zoomPane"}}),_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('div',{staticStyle:{"overflow":"hidden","width":"360px","height":"360px","position":"relative"},on:{"mouseenter":function($event){return _vm.toggleOverlay(true)},"mouseleave":function($event){return _vm.toggleOverlay(false)}}},[_c('img',{ref:"zoomImg",style:({
                                    backgroundColor:
                                        _vm.value.selectedImage.isArtwork && _vm.selectedHexValue
                                            ? _vm.selectedHexValue
                                            : 'rgb(230, 230, 230)',
                                    maxWidth: '360px',
                                }),attrs:{"alt":"Product Image","src":_vm.value.selectedImage.previewImageSrc,"data-zoom":_vm.value.selectedImage.previewImageSrc}}),(_vm.isMobile)?_c('div',{staticClass:"viewerDiv",staticStyle:{"position":"absolute","width":"100%","height":"100%","top":"0px"}}):_vm._e()])]),_c('div',{ref:"boundingBoxContainer",staticStyle:{"background-color":"black"}})])])])]),(_vm.images.length > 1)?_c('v-row',{staticStyle:{"margin":"0px"}},[_c('div',[_c('v-row',{staticStyle:{"margin":"10px 0px 0px 0px","gap":"24px"}},_vm._l((_vm.images),function(img,imgIndex){return _c('div',{key:imgIndex,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectImage(img)}}},[_c('img',{staticClass:"productImageRowItem",style:({
                            border: img.enabled ? '1px solid black' : '1px solid #e3e2e5',
                            backgroundColor:
                                img.isArtwork && _vm.selectedHexValue ? _vm.selectedHexValue : 'rgb(230, 230, 230)',
                        }),attrs:{"alt":"Product Image","src":img.imageSrc}})])}),0)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }