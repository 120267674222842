







































import { Prop, Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import ProductImageViewer from "@/components/ProductImageViewer.vue";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import PackageViewModel from "@/chipply/view-model/PackageViewModel";

@Component({
    components: { ProductImageViewer },
})
export default class PackageDrawerProductInfo extends Vue {
    @Prop({ type: Object })
    public selectedProduct!: ProductViewModel;
    @Prop({ type: Number })
    public color!: number;
    @Prop({ type: Object })
    public packageVm!: PackageViewModel;
}
