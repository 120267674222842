







































































import IItem from "@/chipply/IItem";
import CheckoutConfirmationViewModel from "@/chipply/view-model/CheckoutConfirmationViewModel";
import ItemDisplay from "@/components/ItemDisplay.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import CheckoutReviewViewModel from "../chipply/view-model/CheckoutReviewViewModel";
import { Utils } from "chipply-common";

@Component({
    components: { ItemDisplay },
})
export default class OrderSummary extends Vue {
    public Utils = Utils;

    @Prop({ default: {} })
    public vm!: CheckoutReviewViewModel | CheckoutConfirmationViewModel;

    @Prop({ default: {} })
    public isDrawer!: boolean;

    public get itemsStyle() {
        const baseStyle = {
            maxHeight: "325px",
            overflow: "auto",
            overflowX: "hidden",
            margin: "10px",
            // minHeight: "",
        };

        if (this.$vuetify.breakpoint.mdAndUp) {
            // baseStyle.minHeight = "430px";
        }
        return baseStyle;
    }
}
